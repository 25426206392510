<template>
  <section>
    <h1>{{ $t(`views.${$options.name.toLowerCase()}.title`) }} Ur-Store Management</h1>
  </section>
</template>

<script>
export default {
  name: "Dashboard",
  components: {
  },
  computed: {
  },
  methods: {
  },
  created() {
  },
};
</script>

<style lang="scss"></style>
